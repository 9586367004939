import React from 'react';
import { createContext, useEffect, useState, Suspense } from 'react';
import Navbar from './Components/Header & Navbar/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import WhatsappIcon from './Components/Whatsapp';
import { RotatingLines } from 'react-loader-spinner';
import CareerPage from './Components/Career';
import JobDetails from './Components/Careerapplication';

// Lazy load components
const Homepage = React.lazy(() => import('./Components/Header & Navbar/Hompage/Homepage'));
const About = React.lazy(() => import('./Components/About'));
const Buy = React.lazy(() => import('./Components/Buy'));
const Sell2 = React.lazy(() => import('./Components/Sell2'));
const Compare = React.lazy(() => import('./Components/Compare'));
const Finance = React.lazy(() => import('./Components/Finance'));
const Offer = React.lazy(() => import('./Components/Offer'));
const Blog = React.lazy(() => import('./Components/Blog'));
const Branddeatils = React.lazy(() => import('./Components/Branddeatils'));
const Brandproducts = React.lazy(() => import('./Components/Brandproducts'));
const Contact = React.lazy(() => import('./Components/Contact'));
const Delar = React.lazy(() => import('./Components/Delar'));
const VehicleAuction = React.lazy(() => import('./Components/Delardetails'));
const Usedcars = React.lazy(() => import('./Components/Usedcars'));
const Carrating = React.lazy(() => import('./Components/Carrating'));
const Methodology = React.lazy(() => import('./Components/Methodology'));
const Paper = React.lazy(() => import('./Components/Paper'));
const Insurance = React.lazy(() => import('./Components/Insurance'));
const Testimonials = React.lazy(() => import('./Components/Testimonials'));
const Blogdetails = React.lazy(() => import('./Components/Blogdetails'));
const Searchvechile = React.lazy(() => import('./Components/Searchvechile'));
const TermsandPrivacy = React.lazy(() => import('./Components/TermsandPrivacy'));
const NotFound = React.lazy(() => import("./Components/NotFound"));
export const myContext = createContext();

function App() {
  const [menus, setMenus] = useState();
  const [homepage, setHomePage] = useState();
  const [slugvalue, setslugvalue] = useState();
  const [responseData, setResponseData] = useState(null);
  const [loadingser, setLoadingser] = useState(true);
  const [image, setImage] = useState();
  const [footer, setFooter] = useState(null);


  useEffect(() => {
    axios.get('https://admin.moatamad.com/api/getMenus')
      .then(navresponse => { setMenus(navresponse.data.data) });
    axios.get('https://admin.moatamad.com/api/getHomePageDataBySlug/homes')
      .then(homepageresponse => { setHomePage(homepageresponse.data.data) });
  }, []);

  useEffect(() => {
    axios.get('https://admin.moatamad.com/api/geFooterData')
      .then(response => {
        setFooter(response.data.data);
      })
      .catch(error => {
      });
  }, []);

  const vehicleData = [
    { Make: "Toyota", Model: "Yaris ", Year: 2019, Body: "Hatchback", Mileage: "11,2423 Km" },
    { Make: "Nissan", Model: "Micra", Year: 2019, Body: "Hatchback", Mileage: "11,6601 Km" },
    { Make: "Toyota", Model: "Corolla", Year: 2020, Body: "Sedan", Mileage: "89,505 Km" },
    // other vehicle data
  ];

  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [bidPrices, setBidPrices] = useState({});


  const brands = [
    { image: "https://www.moatamadcars.com/uploads/brands/t1-removebg-preview.png", name: "toyoto", link: "toyoto-car" },
    { image: "https://www.moatamadcars.com/uploads/brands/ho1-removebg-preview.png", name: "honda" },
    // other brands data
  ];

  return (
    <myContext.Provider value={[menus, setMenus, vehicleData, selectedVehicles, setSelectedVehicles, bidPrices, setBidPrices, homepage, setHomePage, slugvalue, setslugvalue, responseData, setResponseData, loadingser, setLoadingser, image, setImage, footer, setFooter]}>
      <BrowserRouter>
        <Navbar />
        <WhatsappIcon />
        <Suspense fallback={<div className='text-center'>
          <RotatingLines
            visible={true}
            height="50"
            width="50"
            color="red"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""

          />
        </div>}>
          <Routes>
            <Route path='/' element={<Homepage />} />
            <Route path='about-us' element={<About />} />
            <Route path='buy' element={<Buy />} />
            <Route path='sell' element={<Sell2 />} />
            <Route path='compare' element={<Compare />} />
            <Route path='finance' element={<Finance />} />
            <Route path='offers' element={<Offer />} />
            <Route path='blog' element={<Blog />} />
            <Route path="/car-info/:brandname" element={<Branddeatils />} />
            <Route path="/searchvechile/:brandname" element={<Branddeatils />} />
            <Route path="/comporte-route/:brandname" element={<Branddeatils />} />
            <Route path='/buy/:brandname' element={<Branddeatils />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/dealer' element={<Delar />} />
            <Route path='/bid-review' element={<VehicleAuction />} />
            <Route path='paperwork' element={<Paper />} />
            <Route path='/used-car-inspections-and-tests' element={<Usedcars />} />
            <Route path='/car-rating-methodology' element={<Carrating />} />
            <Route path='/methodology' element={<Methodology />} />
            <Route path='/insurance' element={<Insurance />} />
            <Route path='testimonials' element={<Testimonials />} />
            <Route path='/news-info/:blogname' element={<Blogdetails />} />
            <Route path='/blog/:blogname' element={<Blogdetails />} />
            <Route path='/privacy-policy' element={<TermsandPrivacy />} />
            <Route path='/terms-and-conditions' element={<TermsandPrivacy />} />
            <Route path='searchvechile' element={<Searchvechile />} />
            <Route path='/careers' element={<CareerPage />} />
            <Route path='/careers/:jobcode' element={<JobDetails />} />

            {brands.map((item) => (
              <Route key={item.name} path={item.name} element={<Brandproducts />} />
            ))}

            {brands.map((item) => (
              <Route key={item.name} path={`/${item.name}/:brandname`} element={<Branddeatils />} />
            ))}
            <Route path="*" element={<NotFound />} />

          </Routes>
        </Suspense>
      </BrowserRouter>
    </myContext.Provider>
  );
}

export default App;
